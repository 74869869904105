import React from 'react'
import styled from 'styled-components'
import { TrustUsBrands } from '../data/trustUsBrands'
import useSwiperRef from '../hooks/useSwiperRef'
import 'swiper/swiper-bundle.min.css'
import SwiperCore, { Autoplay, Navigation, Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwipperPagination from './SwipperPagination'
import FadeUpWhenVisible from './FadeUpWhenVisible'
import theme from '../theme/theme'

const TrustUsWrapper = styled.div`
  /* padding: 39px 0; */
  padding: 39px 0 90px 0;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    /* padding: 30px 0; */
    padding: 30px 0 120px 0;
    width: 100vw;
    max-width: 1068px;
  }
`

const TrustUsHeaderWrapper = styled(FadeUpWhenVisible)`
  color: #000;
  font-weight: bold;
  text-align: center;
  font-size: 20px;
  line-height: 20px;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    font-size: 26px;
    line-height: 31px;
  }
`

const TrustUsHeader = () => (
  <TrustUsHeaderWrapper>Ils nous font confiance</TrustUsHeaderWrapper>
)

const SwiperWrapper = styled(Swiper)`
  padding: 0px 0;

  & .swiper-slide {
    max-width: 136px;

    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
      max-width: 142px;
    }
  }
  .swiper-pagination-bullet {
    background-color: ${({ theme }) => theme.colors.secondary};
  }
`

const TrustUsImg = styled.img`
  width: 136px;
  height: 136px;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    width: 142px;
    height: 142px;
  }

  .swiper-pagination-bullet {
    background-color: ${({ theme }) => theme.colors.secondary};
  }
`

interface TrustUsProps {
  items: TrustUsBrands
}

const TrustUs: React.FC<TrustUsProps> = ({ items }) => {
  return (
    <TrustUsWrapper>
      <TrustUsHeader />
      <SwiperWrapper
        modules={[Pagination, Autoplay]}
        spaceBetween={7}
        slidesPerView="auto"
        centeredSlides={true}
        loop={true}
        autoplay={{ delay: 3500, disableOnInteraction: false }}
        pagination={{ clickable: true }}
      >
        {items.map((item, index) => (
          <SwiperSlide key={index}>
            <FadeUpWhenVisible delay={index * theme.transitions.fadeUp.delay}>
              <TrustUsImg src={item.icon} alt={item.label} loading="lazy" />
            </FadeUpWhenVisible>
          </SwiperSlide>
        ))}
      </SwiperWrapper>
    </TrustUsWrapper>
  )
}

export default TrustUs
