import React from 'react'
import styled from 'styled-components'
import AspectRatio from './AspectRatio'
import FadeUpWhenVisible from './FadeUpWhenVisible'
import theme from '../theme/theme'
import { reparationsLinks } from '../data/menu.context'
import { ITopRepair } from '../hooks/useTopRepairsQuery'
import { repairModelPageBuilder } from '../templates/RepairModelPage.context'
import { StaticImage } from 'gatsby-plugin-image'
import LinkExtended from './LinkExtended'
import Button from './Button'

const TopRecoverCardLink = styled(LinkExtended)<{ color?: string }>`
  background: ${({ color }) => color ?? `#ffffff`};
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  position: relative;
  text-decoration: none;
  height: 100%;
  width: 100%;
  padding: 0 10px;
`

const TopRecoverCardRatio = styled(AspectRatio)`
  width: 100%;
  max-width: 160px;
`

const TopRecoverImgContainer = styled.span`
  width: 100%;
  height: 96px;
  display: flex;
  align-items: center;
  justify-content: center;

  & > span  {
    display: inline-flex;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;

    & > * {
      width: 100%;
      height: 100%;
    }

    img {
      object-fit: contain !important;
    }
  }
`

interface TopRecoverImgProps {
  item: ITopRepair
}

const TopRecoverImg: React.FC<TopRecoverImgProps> = ({ item }) => {
  return (
    <TopRecoverImgContainer>
      <span>
        {item.imageURL ? (
          <img src={item.imageURL ?? ''} alt={item.modele ?? 'Top repair'} />
        ) : (
          <StaticImage
            src="../images/SmartphoneDefault.png"
            alt={item.modele ?? 'Top repair'}
            quality={100}
            placeholder="blurred"
            width={170}
            height={170}
          />
        )}
      </span>
    </TopRecoverImgContainer>
  )
}

const TopRecoverInfosWrapper = styled.div`
  color: ${({ theme }) => theme.colors.secondary};
  font-size: 12px;
  text-align: center;
`

interface TopRecoverInfosProps {
  brand: string
  model: string
  type: string
}

const TopRecoverInfos: React.FC<TopRecoverInfosProps> = ({
  brand,
  type,
  model,
}) => (
  <TopRecoverInfosWrapper>
    <div>
      {brand} {model}
    </div>
    <div>{type}</div>
  </TopRecoverInfosWrapper>
)

const formatPrice = (price: number) =>
  new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(
    price
  )

const TopRecoverPriceWrapper = styled.div`
  font-size: 12px;
  color: #000;
`

const TopRecoverPriceNumber = styled.span`
  font-size: 15px;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.secondary};
`

interface TopRecoverPriceProps {
  price: number
}

const TopRecoverPrice: React.FC<TopRecoverPriceProps> = ({ price }) => (
  <TopRecoverPriceWrapper>
    à partir de{' '}
    <TopRecoverPriceNumber>{formatPrice(price)}</TopRecoverPriceNumber>
  </TopRecoverPriceWrapper>
)

interface TopRecoverCardProps {
  item: ITopRepair
  index: number
}

const TopRecoverCard: React.FC<TopRecoverCardProps> = ({ item, index }) => (
  <TopRecoverCardRatio
    desktop={{ width: 160, height: 208 }}
    mobile={{ width: 160, height: 208 }}
  >
    <FadeUpWhenVisible delay={index * theme.transitions.fadeUp.delay}>
      <TopRecoverCardLink
        to={repairModelPageBuilder(item.brand ?? '', item.modeleSlug ?? '')}
        noprefetch
      >
        <TopRecoverImg item={item} />
        <TopRecoverInfos
          brand={item.brand ?? ''}
          model={item.modele ?? ''}
          type={item.label ?? ''}
        />
        <TopRecoverPrice price={item.price ?? 0} />
      </TopRecoverCardLink>
    </FadeUpWhenVisible>
  </TopRecoverCardRatio>
)

const LastCardLabel = styled.label`
  color: white;
  text-align: center;
  font-weight: 700;
  margin-bottom: 33px;
`

const LastCardButton = styled(Button)`
  font-siz: 12px;
  border: 3px solid white;
  padding: 2px 17px;
  border-radius: 9px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.3));
`
interface TopRecoverCardLastProps {
  link: string
  label: string
  buttonLabel: string
  index: number
}

const TopRecoverLastCard: React.FC<TopRecoverCardLastProps> = ({
  link,
  label,
  buttonLabel,
  index,
}) => (
  <TopRecoverCardRatio
    desktop={{ width: 160, height: 208 }}
    mobile={{ width: 160, height: 208 }}
  >
    <FadeUpWhenVisible delay={index * theme.transitions.fadeUp.delay}>
      <TopRecoverCardLink color="#00BFB2" to={link} noprefetch>
        {/* label */}
        <LastCardLabel>{label}</LastCardLabel>
        {/* //button */}
        <LastCardButton>{buttonLabel}</LastCardButton>
      </TopRecoverCardLink>
    </FadeUpWhenVisible>
  </TopRecoverCardRatio>
)

const TopRecoversWrapper = styled.div`
  background: white;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    padding-top: 30px;
  }
`

const TopRecoversGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, 160px);
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin: 0 auto;
  justify-content: space-around;
  padding-top: 5px;
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    padding-top: 24px;
  }
`

const TopRecoversTitleMobileWrapper = styled.h2`
  display: flex;
  justify-content: center;
  padding-top: 5px;
  padding-bottom: 2px;
  line-height: 24px;
  font-size: 20px;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.secondary};
  max-width: min(100%, 100vw);

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    display: none;
  }
`
interface Props {
  children: React.ReactNode
}

const TopRecoversTitleMobile: React.FC<Props> = ({ children }) => (
  <TopRecoversTitleMobileWrapper>{children}</TopRecoversTitleMobileWrapper>
)

const TopRecoversTitleDesktop = styled.h2`
  display: none;
  background-repeat: no-repeat;
  text-align: center;
  font-style: normal;
  font-weight: bold;
  font-size: 26px;
  line-height: 31px;
  text-align: center;
  color: ${({ theme }) => theme.colors.secondary};

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    display: block;
  }
`

const TopRecoversDescription = styled.p`
  display: none;
  padding: 0 10px;
  text-align: center;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: ${({ theme }) => theme.colors.body};

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    display: block;
  }
`

const TopRecoversHeader: React.FC = () => (
  <FadeUpWhenVisible>
    <TopRecoversTitleDesktop>Top des réparations</TopRecoversTitleDesktop>
    <TopRecoversTitleMobile>Top des réparations</TopRecoversTitleMobile>
    <TopRecoversDescription>
      Cliquez sur le modèle de téléphone pour accéder à la page réparation
    </TopRecoversDescription>
  </FadeUpWhenVisible>
)

const TopRecoversFooterMoreLink = styled(LinkExtended)`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 12px;
  line-height: 12px;
  font-weight: bold;
  display: inline-block;
  margin-top: 18px;
  max-width: 144px;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    max-width: unset;
    font-size: 12px;
    line-height: 14px;
  }
`

interface TopRecoversFooterMoreWrapperProps {
  mobile?: boolean
}

const TopRecoversFooterMoreWrapper = styled.div<TopRecoversFooterMoreWrapperProps>`
  min-height: 74px;
  justify-content: flex-end;
  padding-right: 10px;
  display: ${({ mobile }) => (mobile ? 'flex' : 'none')};

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    display: ${({ mobile }) => (mobile ? 'none' : 'flex')};
    max-width: 864px;
    margin: 0 auto;
  }
`

interface TopRecoversFooterMoreProps {
  href: string
  mobile?: boolean
  children: React.ReactNode
}

const TopRecoversFooterMore: React.FC<TopRecoversFooterMoreProps> = ({
  href,
  children,
  mobile,
}) => (
  <TopRecoversFooterMoreWrapper mobile={mobile}>
    <TopRecoversFooterMoreLink to={href} noprefetch>
      {children}
    </TopRecoversFooterMoreLink>
  </TopRecoversFooterMoreWrapper>
)

interface TopRecoversProps {
  items: ITopRepair[]
}

const TopRecovers: React.FC<TopRecoversProps> = ({ items }) => (
  <TopRecoversWrapper>
    <TopRecoversHeader />
    <TopRecoversGrid>
      {items.map((item, index) => (
        <TopRecoverCard key={index} index={index} item={item} />
      ))}
      {/* //retrouvez tous nos appareils */}
      <TopRecoverLastCard
        key={items.length + 1}
        index={items.length + 1}
        label={'Retrouvez tous nos modèles à réparer !'}
        buttonLabel={'Je répare'}
        link={reparationsLinks.all}
      ></TopRecoverLastCard>
    </TopRecoversGrid>
  </TopRecoversWrapper>
)

export default TopRecovers
